import React from 'react';
import {
  faCamera, faVolumeLow, faFlask, faSun, faBolt,
  faWeightHanging, faDroplet, faTemperatureFull, faPeopleArrows,
  faCubesStacked, faCompass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

function SensorTypeIcon({ sensorType, size }: SensorTypeIconProps) {
  function getIcon(st: string) {
    switch (st) {
      case 'camera':
        return <FontAwesomeIcon icon={faCamera} size={size} />;
      case 'sound':
        return <FontAwesomeIcon icon={faVolumeLow} size={size} />;
      case 'chemical':
        return <FontAwesomeIcon icon={faFlask} size={size} />;
      case 'climate':
        return <FontAwesomeIcon icon={faSun} size={size} />;
      case 'electrical':
        return <FontAwesomeIcon icon={faBolt} size={size} />;
      case 'pressure':
        return <FontAwesomeIcon icon={faWeightHanging} size={size} />;
      case 'liquid':
        return <FontAwesomeIcon icon={faDroplet} size={size} />;
      case 'temperature':
        return <FontAwesomeIcon icon={faTemperatureFull} size={size} />;
      case 'presence':
        return <FontAwesomeIcon icon={faPeopleArrows} size={size} />;
      case 'density':
        return <FontAwesomeIcon icon={faCubesStacked} size={size} />;
      case 'position':
        return <FontAwesomeIcon icon={faCompass} size={size} />;
      default:
        return '';
    }
  }

  return (
    <div className="w-5 content-center text-center">
      {getIcon(sensorType)}
    </div>
  );
}

type SensorTypeIconProps = {
  sensorType: string
  size: SizeProp
};

export default SensorTypeIcon;
