import './index.css';

import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Layout from './components/Layout';
import LeafletMap from './components/LeafletMap';
import About from './components/About';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LeafletMap />} />
        <Route path="about" element={<About />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="*" element={<LeafletMap />} />
      </Route>
    </Routes>
  );
}

export default App;
