import { Link, Outlet } from 'react-router-dom';
import React from 'react';
import { ReactComponent as WecityLogo } from '../images/wecity_logo.svg';
import BrabantLogo from '../images/logo/brabant.png';

function Layout() {
  const supportedLogos = {
    wecity: <WecityLogo height={80} />,
    breda: <WecityLogo height={80} />,
    utrecht: <WecityLogo height={80} />,
    brabant: <img src={BrabantLogo} alt="brabant" className="max-h-20 m-auto" />,
  };

  function loadLogo() {
    const logo = Object.entries(supportedLogos)
      .find(([key, _value]) => (key === process.env.REACT_APP_AFFILIATE_NAME));

    return logo ? logo[1] : supportedLogos.wecity;
  }

  return (
    <div className="map overflow-y-hidden">
      <div className="bg-wc-blue top-0 z-[420] h-24 py-2">
        <div className="grid grid-cols-3 justify-items-center">
          <div className="h-full flex flex-row space-x-8 my-auto items-center text-lg justify-start" />
          <div className="text-wc-gray-500 text-sm text-center">
            <Link to="/" className="text-white">
              {loadLogo()}
            </Link>
          </div>

          <div className="h-full flex flex-row space-x-8 my-auto items-center text-lg">
            <div>
              <Link to="/" className="text-white">Kaart</Link>
            </div>
            <div>
              <Link to="/about" className="text-white">Over het register</Link>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Layout;
