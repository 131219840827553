import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import useRegisteredSensorSchema from '../hooks/useRegisteredSensorSchema';
import Loading from './Loading';
import { Option } from './Checkbox';
import MultiCheckbox from './MultiCheckbox';
import { Filter } from '../hooks/usePublicAssets';
import SensorTypeIcon from './SensorTypeIcon';

function Drawer({
  isOpen, setIsOpen, setFilters, totalResultCount, currentResultCount,
}: DrawerProps) {
  const { schema, isLoading } = useRegisteredSensorSchema();
  const { t } = useTranslation();

  const [sensorTypeOptions, setSensorTypeOptions] = useState<Option[]>([]);
  const [themeOptions, setThemeOptions] = useState<Option[]>([]);
  const [personalDataOptions, setPersonalDataOptions] = useState<Option[]>([]);

  function validationSensorTypeStringToOptionList(validationString: string): Option[] {
    return validationString.replace('in:', '').split(',').map((item, index) => (
      {
        name:
  <>
    <SensorTypeIcon sensorType={item.replaceAll('_', ' ')} size="1x" />
    <div className="capitalize-first">{t(item.replaceAll('_', ' '))}</div>
  </>,
        index,
        value: item,
        checked: false,
      } as Option
    ));
  }

  function validationStringToOptionList(validationString: string): Option[] {
    return validationString.replace('in:', '').split(',').map((item, index) => (
      {
        name: <div className="capitalize-first">{t(item.replaceAll('_', ' '))}</div>,
        index,
        value: item,
        checked: false,
      } as Option
    ));
  }

  useEffect(() => {
    if (!schema || sensorTypeOptions.length > 0 || themeOptions.length > 0) {
      return;
    }

    const sensorPropertyType = schema.propertyTypes.find((propertyType) => propertyType.name === 'sensor_type');
    const themePropertyType = schema.propertyTypes.find((propertyType) => propertyType.name === 'theme');
    const personalDataPropertyType = schema.propertyTypes.find((propertyType) => propertyType.name === 'processes_personal_data');

    setSensorTypeOptions(
      sensorPropertyType
        ? validationSensorTypeStringToOptionList(sensorPropertyType.validation) : [],
    );
    setThemeOptions(
      themePropertyType
        ? validationStringToOptionList(themePropertyType.validation) : [],
    );
    setPersonalDataOptions(personalDataPropertyType ? ([{
      name: t('sensor processes personal data'),
      index: 0,
      value: '1',
      checked: false,
    } as Option]) : []);
  }, [isLoading, schema]);

  function createFilters(): Filter[] {
    const themes = themeOptions.filter((o) => o.checked).map((option) => option.value);
    const sensorTypes = sensorTypeOptions.filter((o) => o.checked).map((option) => option.value);
    const personalData = personalDataOptions.filter((o) => o.checked).map((option) => option.value);

    let filters: Filter[] = [];
    if (themes.length > 0) {
      filters = [{ key: 'theme', values: themes }];
    }

    if (sensorTypes.length > 0) {
      filters = [...filters, { key: 'sensor_type', values: sensorTypes }];
    }

    if (personalData.length > 0) {
      filters = [...filters, { key: 'processes_personal_data', values: personalData }];
    }

    return filters;
  }

  useEffect(() => {
    setFilters(createFilters());
  }, [themeOptions, sensorTypeOptions, personalDataOptions]);

  function resetFilters() {
    setSensorTypeOptions(sensorTypeOptions.map((option) => ({ ...option, ...{ checked: false } })));
    setThemeOptions(themeOptions.map((option) => ({ ...option, ...{ checked: false } })));
    setPersonalDataOptions(
      personalDataOptions.map((option) => ({ ...option, ...{ checked: false } })),
    );
  }

  return (
    <div
      className={
        `z-[430] overflow-x-hidden w-screen max-w-xs top-[6rem] left-0 absolute bg-white h-[calc(100%_-_6rem)] shadow-xl delay-400 duration-500 ease-in-out transition-all transform ${
          isOpen ? ' translate-x-0 ' : ' -translate-x-full '}`
      }
    >
      <div className="flex flex-col p-2">
        <div className="flex justify-between">
          <h2 className="font-bold text-2xl mb-4 capitalize-first">{t('filters')}</h2>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} size="xl" />
          </button>
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="flex-col space-y-4">
              <div>
                <button type="button" className="bg-wc-blue text-white py-1 px-2 rounded capitalize-first" onClick={resetFilters}>
                  {t('remove all filters')}
                </button>
              </div>
              <div>{t('sensor result count', { current: currentResultCount, total: totalResultCount })}</div>
              <div>
                <div className="font-bold text-xl my-2 capitalize">{t('sensor types')}</div>
                <MultiCheckbox options={sensorTypeOptions} onChangeHandler={setSensorTypeOptions} />
              </div>
              <div>
                <div className="font-bold text-xl my-2 capitalize">{t('themes')}</div>
                <MultiCheckbox options={themeOptions} onChangeHandler={setThemeOptions} />
              </div>
              <div>
                <div className="font-bold text-xl my-2 capitalize">{t('personal data')}</div>
                <MultiCheckbox
                  options={personalDataOptions}
                  onChangeHandler={setPersonalDataOptions}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export type DrawerProps = {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  setFilters: (v: Filter[]) => void
  currentResultCount: number
  totalResultCount: number
};

export default Drawer;
